import methods from '@/enums/methods';

const { default: Thoth } = require('../base/thoth');

class TaskService extends Thoth {
    amounTransacted({ ec, initialDate, endDate }) {
        return this.fetch({
            method: methods.POST,
            data: { ec, initialDate, finalDate: endDate },
            url: '/amount-transacted/consult',
        });
    }

    executeTask({ url, initialDate, endDate }) {
        return this.fetch({
            method: methods.POST,
            data: { initialDate, finalDate: endDate },
            url,
        });
    }
}

export default new TaskService();
