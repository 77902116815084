<template>
  <b-row>
    <b-col
      v-for="item in tasks"
      :key="item.title"
      md="3"
    >
      <b-button
        variant="primary"
        :disabled="(!isValidTask() || !isValidConsult())"
        class="mt-1 mx-auto d-block w-100"
        @click="$emit('execute-task', item)"
      >
        {{ item.title }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue';

import { daysBetweenDates } from '@/utils/date/days-between-date';

export default {
    components: { BRow, BCol, BButton },
    props: {
        tasks: {
            type: Array,
            required: true,
        },

        params: {
            type: Object,
            required: true,
        },
    },
    methods: {
        isValidTask() {
            const different = daysBetweenDates(new Date(this.params.initialDate), new Date(this.params.endDate));
            return different >= -5;
        },

        isValidConsult() {
            const different = daysBetweenDates(new Date(this.params.initialDate), new Date(this.params.endDate));
            return different <= 0;
        },
    },
};
</script>
