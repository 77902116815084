<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        label="ECs"
        label-for="ec"
      >
        <multiselect
          id="ec"
          v-model="props.ec"
          :options="merchantsOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder=""
          select-label=""
          selected-label=""
          deselect-label=""
          :show-no-options="false"
          label="text"
          track-by="value"
        />
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group
        label="Grupo"
        label-for="group"
      >
        <multiselect
          id="egroup"
          v-model="props.group"
          :options="groupsOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder=""
          select-label=""
          selected-label=""
          deselect-label=""
          :show-no-options="false"
          label="text"
          track-by="value"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';

export default {
    components: {
        BRow, BCol, Multiselect, BFormGroup,
    },
    props: {
        props: {
            type: Object,
            required: true,
        },

        merchantsOptions: {
            type: Array,
            required: true,
        },

        groupsOptions: {
            type: Array,
            required: true,
        },
    },
};

</script>

<style lang="scss">

.multiselect__option--highlight {
    background: #2E2C6C !important;
}

.multiselect__tag {
    background: #2E2C6C !important;
}

.dark-layout {
    .multiselect__tags {
        background-color: #283046;
        border-color: #3b4253;
    }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
